import styles from "./styles.module.scss";
import { List } from "antd";

export const ExerciseList = ({ list, renderParts, loading }) => {
  return (
    <List
      className={styles.container}
      bordered
      loading={loading}
      dataSource={list}
      renderItem={(item, index) => (
        <List.Item className={styles.listItemStyles}>
          {renderParts(item, index)}
        </List.Item>
      )}
    />
  );
};
