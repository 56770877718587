import { Button } from "antd";
import { format } from "date-fns";
import styles from "./styles.module.scss";
import { MessageOutlined, OrderedListOutlined } from "@ant-design/icons";

export const HistoryItem = ({ data, onClick }) => {
  const formattedDate = format(data.created_at, "dd-MM-yyyy: HH:mm");
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.row}>
          <h4 className={styles.title}>{data.tense_name}</h4>
          <h5 className={styles.title}>{data.level_name}</h5>
          <div className="count">
            <OrderedListOutlined style={{ color: "#01010133" }} /> {data.count}
          </div>
          <div className="user_answers">
            {" "}
            <MessageOutlined style={{ color: "#01010133" }} />{" "}
            {data.user_answers}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.date}>{formattedDate}</div>
        </div>
      </div>
      <div className={styles.right}>
        <Button size="small" onClick={onClick}>
          Load
        </Button>
      </div>
    </div>
  );
};
