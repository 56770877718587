import styles from "./styles.module.scss";

export const ContactUs = () => {
  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <h1 className={styles.headTitle}>Contact us</h1>
        <p className={styles.text}>
          We are always happy to hear your criticism, questions, suggestions, or
          wishes.
          <br />
          <br />
        </p>
      </section>
    </div>
  );
};
