import { Drawer } from "antd";
import { HistoryItem } from "./HistoryItem";
import styles from "./styles.module.scss";

export const History = ({
  open,
  onOpen,
  onClose,
  getHistory,
  data,
  onLoadExercise,
}) => {
  const handleLoad = (request_id) => {
    onLoadExercise(request_id);
    onClose();
  };

  return (
    <Drawer
      title="History"
      placement={"right"}
      closable={false}
      onClose={onClose}
      open={open}
      key={"right"}
    >
      <div className={styles.list}>
        {data?.map((historyItem) => (
          <HistoryItem
            data={historyItem}
            onClick={() => handleLoad(historyItem.id)}
          />
        ))}
      </div>
    </Drawer>
  );
};
