import { Modal } from "antd";

export const AnswersModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  answers,
}) => {
  return (
    <Modal
      title="Answers"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {answers?.map((text, index) => (
        <p>
          {index + 1}. {text}.
        </p>
      ))}
    </Modal>
  );
};
