import React, { useEffect } from "react";
import { Alert, Button, Layout } from "antd";
import { notification, Progress } from "antd";
import { useExercise } from "../features/exercises/module/useExercise";
import { ExerciseList } from "../features/fillTheBlank/ui/ExerciseList";
import { ExercisesCondition } from "../features/exercises/ui/ExercisesCondition";
import { useFillTheBlankList } from "../features/fillTheBlank/module/useFillTheBlankList";
import { FillInTheBlanks } from "../features/fillTheBlank/ui/FillInTheBlank/FillInTheBlank";
import { useForm } from "react-hook-form";
import {
  CheckOutlined,
  CopyOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../features/user/module/userSlice";

import styles from "./HomePage.module.scss";
import { AnswersModal } from "../features/modal/ui/Answers";
import { useModal } from "../features/modal/module/useModal";

const { Content } = Layout;

function HomePage() {
  const [api, contextHolder] = notification.useNotification();
  const user = useSelector(selectCurrentUser);
  const { isModalOpen, showModal, handleOk, handleCancel } = useModal();
  const openNotification = () => {
    api.success({
      message: `Copied to clipboard`,
      placement: "bottomRight",
    });
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm();

  const {
    generateExercise,
    getGuestExercise,
    handleCheckAnswers,
    handleCheckGuestAnswers,
    onGetLatestExercise,
    onGetLatestGuestExercise,
    onGetAnswers,
    exercise,
    isFetchingExercises,
    isLoadingExercises,
    answers,
    total,
    correct_count,
    latestExerciseRequest,
    initialData,
    user_answers,
    request_id,
    resetAll,
    clearState,
  } = useExercise({ notificationApi: api });

  useEffect(() => {
    resetAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request_id]);

  const { sentasesWithGaps, checkAnswers, doneIds, clearDone } =
    useFillTheBlankList(
      {
        sentanses: exercise,
        reset,
      },
      latestExerciseRequest
    );

  useEffect(() => {
    if (!user) {
      return;
    }
    if (!latestExerciseRequest && exercise.length > 0 && user.id) {
      clearState();
    }

    if (!latestExerciseRequest) {
      onGetLatestExercise(user.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  useEffect(() => {
    if (user) {
      return;
    }

    onGetLatestGuestExercise();
  }, []);

  useEffect(() => {
    reset();
    if (user_answers?.length) {
      user_answers.forEach((answers) => {
        setValue(answers.key, answers.user_answer);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_answers]);

  const onSubmit = async (data) => {
    if (user) {
      const { correct_answers } = (await handleCheckAnswers(data)) || {};

      checkAnswers(data, correct_answers, setError, clearErrors);
    } else {
      const { correct_answers } = (await handleCheckGuestAnswers(data)) || {};

      checkAnswers(data, correct_answers, setError, clearErrors);
    }
  };

  const handleGenerateExercise = ({ level, tenses }) => {
    user
      ? generateExercise({ level, tenses })
      : getGuestExercise({ level, tenses });

    clearDone();
    reset();
  };

  const handleDownloadExercies = () => {
    const data = exercise
      .map((item, index) => `${index + 1}. ${item} \n`)
      .toString()
      .replaceAll(",", "");
    navigator.clipboard.writeText(data);
    openNotification();
  };

  const handlegetAnswers = () => {
    showModal();
    onGetAnswers();
  };

  return (
    <Layout className={styles.layout}>
      {contextHolder}
      <Content className={styles.siteLayoutContent}>
        <ExercisesCondition
          onSubmit={handleGenerateExercise}
          loading={isFetchingExercises || isLoadingExercises}
          enabled={user}
          initialData={initialData}
        />

        <div className={styles.listWrapper}>
          <div className={styles.actionWrapper}>
            {sentasesWithGaps?.length > 0 && (
              <Progress
                style={{ width: "200px" }}
                percent={((correct_count / total) * 100 || 0).toFixed(1)}
                size="small"
                status="active"
              />
            )}
            <div className={styles.right}>
              {sentasesWithGaps?.length > 0 && (
                <>
                  {user && (
                    <Button
                      size="small"
                      icon={<QuestionOutlined />}
                      onClick={handlegetAnswers}
                      disabled={isLoadingExercises}
                    >
                      Get Answers
                    </Button>
                  )}
                  <Button
                    size="small"
                    icon={<CopyOutlined />}
                    onClick={handleDownloadExercies}
                    disabled={isLoadingExercises}
                  >
                    Copy to clipboard
                  </Button>

                  <Button
                    size="small"
                    icon={<CheckOutlined />}
                    onClick={handleSubmit(onSubmit)}
                    disabled={isLoadingExercises}
                  >
                    Check all answers
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className={styles.actionWrapper}>
            <Alert
              message="Please do not use contraction. Example: Do not instead of don't"
              type="info"
              showIcon
              closable
            />
            {correct_count === total && !!correct_count && (
              <Alert
                message="Good job, keep going!!!"
                type="success"
                showIcon
                closable
              />
            )}
          </div>
          <ExerciseList
            loading={isLoadingExercises}
            list={sentasesWithGaps || []}
            renderParts={(item, index) => (
              <FillInTheBlanks
                index={index}
                sentasesParts={item}
                control={control}
                errors={errors}
                doneIds={doneIds}
              />
            )}
          />
        </div>
      </Content>
      <AnswersModal
        isModalOpen={isModalOpen}
        answers={answers}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </Layout>
  );
}

export { HomePage };
