// src/slices/exerciseSelectors.js

export const selectExercises = (state) => state.exercise.exercises;
export const selectLoading = (state) => state.exercise.loading;
export const selectExerciseParams = (state) => {
  return {
    level: state.exercise.level,
    tense: state.exercise.tense,
  };
};
