import { useEffect, useState } from "react";

import { Button, Radio, Select } from "antd";
import { optionsLevelEnglish, optionsTense } from "../../utils";

import styles from "./styled.module.scss";

export const ExercisesCondition = ({
  loading,
  initialData,
  onSubmit,
  enabled,
}) => {
  const [selectLevel, setSelectLevel] = useState("B1");
  const [selectedItems, setSelectedItems] = useState("Present Simple");

  useEffect(() => {
    initialData.level && setSelectLevel(initialData.level);
    initialData.tense && setSelectedItems(initialData.tense);
  }, [initialData?.level, initialData?.tense]);

  const handleChangeLevel = ({ target: { value } }) => {
    setSelectLevel(value);
  };

  const handleGenerate = () => {
    onSubmit({ level: selectLevel, tenses: [selectedItems] });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.leftWrapper}>
        <div className={styles.container}>
          <h4 className={styles.dificultTitle}>Language level selection</h4>
          <Radio.Group
            optionType="button"
            value={selectLevel}
            options={optionsLevelEnglish}
            onChange={handleChangeLevel}
          />
        </div>

        <div className={styles.container}>
          <h4 className={styles.dificultTitle}>English tense selection</h4>
          <Select
            className={styles.select}
            maxTagCount={"responsive"}
            placeholder="Please select"
            value={selectedItems}
            onChange={setSelectedItems}
            options={optionsTense}
          />
        </div>
      </div>
      <div className={styles.container}>
        <Button
          onClick={handleGenerate}
          loading={loading}
          style={{ width: "100%" }}
        >
          Generate tasks
        </Button>
      </div>
    </div>
  );
};
