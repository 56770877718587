import { Popover, Avatar, Button } from "antd";
import { selectCurrentUser } from "../../../user/module/userSlice";
import { Login } from "../Login/Login";
import { useSelector } from "react-redux";
import { useAuth } from "../../module/useAuth";
import styles from "./styles.module.scss";
import { useEffect } from "react";
import Cookies from "js-cookie";

export const Auth = ({ renderMenu }) => {
  const user = useSelector(selectCurrentUser);
  const { doLogout } = useAuth();

  useEffect(() => {
    const token = Cookies.get("authToken");

    if (!token && user) {
      doLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    doLogout();
  };

  return (
    <>
      {!user && <Login />}
      {user && (
        <Popover
          zIndex={0}
          content={
            <div className={styles.list}>
              {renderMenu}
              <Button onClick={handleLogout}>Logout</Button>
            </div>
          }
          trigger="click"
        >
          <Avatar
            className={styles.avatar}
            size={40}
            src={<img src={user.picture} alt="avatar" />}
          />
        </Popover>
      )}
    </>
  );
};
