import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { HomePage } from "./pages/HomePage";
import "./app.module.scss";
import { TermsOfUse } from "./pages/TermsOfUse/TermsOfUse";
import { PrivacyPolicy } from "./pages/PrivacyPolicy/PrivacyPolicy";
import { Layout } from "./shared/ui/Layout/Layout";
import { ContactUs } from "./pages/ContactUS/ContactUs";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { CookieBanner } from "./shared/ui/CookiesBanner";

function App() {
  useEffect(() => {
    ReactGA.initialize("G-WW6BNRS64X");
  }, []);

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/terms" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contacts" element={<ContactUs />} />
        </Routes>
        <CookieBanner />
      </Layout>
    </Router>
  );
}

export default App;
