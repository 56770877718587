export const optionsTense = [{
    label: 'Present Simple',
    value: 'Present Simple',
},
{
    label: 'Present Continuous',
    value: 'Present Continuous',
},
{
    label: 'Present Perfect',
    value: 'Present Perfect',
},
{
    label: 'Present Perfect Continuous',
    value: 'Present Perfect Continuous',
},
{
    label: 'Past Simple',
    value: 'Past Simple',
},
{
    label: 'Past Continuous',
    value: 'Past Continuous',
},
{
    label: 'Past Perfect',
    value: 'Past Perfect',
},
{
    label: 'Past Perfect Continuous',
    value: 'Past Perfect Continuous',
},
{
    label: 'Future Simple',
    value: 'Future Simple',
},
{
    label: 'Going to',
    value: 'Going to',
},
{
    label: 'Future Continuoue',
    value: 'Future Continuous',
},
{
    label: 'Future Perfect',
    value: 'Future Perfect',
},
{
    label: 'Future Perfect Continuous',
    value: 'Future Perfect Continuous',
},
];

export const optionsLevelEnglish = [
    { label: 'A1', value: 'A1' },
    { label: 'A2', value: 'A2' },
    { label: 'B1', value: 'B1' },
    { label: 'B2', value: 'B2' },
    { label: 'C1', value: 'C1' },
    { label: 'C2', value: 'C2' },
];