import styles from "./styles.module.scss";

export const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <h1 className={styles.headTitle}>Privacy Policy</h1>
        <p className={styles.text}>
          This Privacy Policy explains how GrammaGen AI ("we," "our," or "us")
          collects, uses, and discloses your information when you use our
          website and services ("Service"). We are committed to protecting your
          privacy and handling your data transparently in accordance with the
          General Data Protection Regulation (GDPR).
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>1. Information We Collect</h2>
        <p className={styles.text}>
          We collect the following types of information when you use GrammaGen
          AI:
        </p>
        <ul className={styles.list}>
          <li>
            <strong>Account Information:</strong> When you sign in with Google,
            we collect your name, email address, Google ID, and profile picture.
          </li>
          <li>
            <strong>Usage Data:</strong> Information such as tasks generated,
            responses entered, and task history to enhance and personalize the
            Service.
          </li>
          <li>
            <strong>Device and Analytics Data:</strong> We use Google Analytics
            to collect anonymous data on user interactions with the Service
            (e.g., IP address, device type, and browser type). This data helps
            us improve user experience.
          </li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>2. How We Use Your Information</h2>
        <p className={styles.text}>We use the information collected to:</p>
        <ul className={styles.list}>
          <li>Provide, maintain, and improve our Service.</li>
          <li>
            Personalize your experience and remember your settings and
            preferences.
          </li>
          <li>Analyze usage and performance trends to enhance the Service.</li>
          <li>
            Communicate with you about updates, offers, or changes to our
            Service.
          </li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>3. Legal Basis for Processing</h2>
        <p className={styles.text}>
          We process your personal data based on the following legal grounds:
        </p>
        <ul className={styles.list}>
          <li>
            <strong>Consent:</strong> By using GrammaGen AI and creating an
            account, you consent to our data practices as outlined in this
            policy.
          </li>
          <li>
            <strong>Contractual Necessity:</strong> Data is processed as
            required to provide and support the Service.
          </li>
          <li>
            <strong>Legitimate Interests:</strong> To improve the Service and
            enhance user experience.
          </li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>4. Data Sharing and Disclosure</h2>
        <p className={styles.text}>
          We do not sell your personal data to third parties. However, we may
          share your information with:
        </p>
        <ul className={styles.list}>
          <li>
            <strong>Service Providers:</strong> Third-party vendors like Google
            Analytics, who assist us in improving and analyzing the Service.
          </li>
          <li>
            <strong>Legal Requirements:</strong> When required by law,
            regulation, or legal process to protect our rights and comply with
            applicable laws.
          </li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>5. Data Retention</h2>
        <p className={styles.text}>
          We retain your data only for as long as necessary to provide the
          Service or as required by law. You may request deletion of your data
          at any time by contacting us.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>6. Your Rights Under GDPR</h2>
        <p className={styles.text}>
          If you are located in the European Union, you have the following
          rights regarding your personal data:
        </p>
        <ul className={styles.list}>
          <li>
            <strong>Access:</strong> You have the right to request a copy of
            your personal data.
          </li>
          <li>
            <strong>Rectification:</strong> You can request correction of
            inaccurate or incomplete information.
          </li>
          <li>
            <strong>Erasure:</strong> You may request deletion of your personal
            data.
          </li>
          <li>
            <strong>Restriction:</strong> You have the right to limit how your
            data is processed.
          </li>
          <li>
            <strong>Data Portability:</strong> You may request to receive your
            data in a machine-readable format.
          </li>
          <li>
            <strong>Objection:</strong> You may object to data processing based
            on legitimate interests.
          </li>
        </ul>
        <p className={styles.text}>
          To exercise these rights, please contact us at{" "}
          <a href="mailto:support@grammagen.com">support@grammagen.com</a>.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>7. Security</h2>
        <p className={styles.text}>
          We take reasonable measures to protect your data from unauthorized
          access, disclosure, alteration, or destruction. However, no
          internet-based service can be fully secure, so we cannot guarantee the
          absolute security of your data.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>8. Changes to This Privacy Policy</h2>
        <p className={styles.text}>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. We
          encourage you to review this Privacy Policy periodically for any
          updates.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>9. Contact Us</h2>
        <p className={styles.text}>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at{" "}
          <a href="mailto:support@grammagen.com">support@grammagen.com</a>.
        </p>
      </section>
    </div>
  );
};
