import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Button } from "antd";

export const CookieBanner = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (!cookiesAccepted) {
      setIsBannerVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setIsBannerVisible(false);
  };
  if (!isBannerVisible) {
    return <></>; // Якщо банер не потрібен, не відображаємо його
  }
  return (
    <div className={styles.banner}>
      <p className={styles.text}>
        We use cookies to improve your experience on our site. By continuing to
        use our site, you accept our use of cookies. Read more in our{" "}
        <a className={styles.link} href="/privacy-policy">
          Privacy Policy
        </a>
        .
      </p>
      <Button onClick={acceptCookies} className={styles.button}>
        Accept
      </Button>
    </div>
  );
};
