import styles from "./styles.module.scss";
export const TermsOfUse = () => {
  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <h1 className={styles.headTitle}>Terms and Conditions</h1>
        <p className={styles.text}>
          Welcome to GrammaGen AI. These Terms and Conditions (“Terms”) govern
          your access and use of our service (“Service”), including any content,
          functionality, and services offered. By accessing or using the
          Service, you agree to be bound by these Terms. If you do not agree,
          please do not use our Service.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>1. Eligibility and Account</h2>
        <p className={styles.text}>
          You must be at least 13 years old to use GrammaGen AI. By creating an
          account, you confirm that the information provided is accurate and
          complete, and you agree to keep it updated. You are responsible for
          maintaining the confidentiality of your login credentials and all
          activities under your account.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>2. Use of the Service</h2>
        <p className={styles.text}>
          Our Service allows users to generate English language exercises,
          select difficulty levels, and save a history of generated tasks and
          responses. You agree not to use GrammaGen AI for any illegal or
          unauthorized purposes and to comply with all applicable laws and
          regulations.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>3. User Data and Privacy</h2>
        <p className={styles.text}>
          We respect your privacy and process personal data in compliance with
          the General Data Protection Regulation (GDPR). Please refer to our{" "}
          <a href="/privacy-policy">Privacy Policy</a> for details on how we
          collect, use, and protect your data.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>4. Data Collection and Usage</h2>
        <p className={styles.text}>
          By using GrammaGen AI, you agree that we may collect data such as your
          task inputs and responses to personalize and improve our Service. We
          use Google Analytics to gather non-personal, aggregated data on user
          interactions, and OpenAI’s API for task generation.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>5. Intellectual Property</h2>
        <p className={styles.text}>
          All content provided by GrammaGen AI, including tasks generated by the
          AI, text, and design elements, is owned by us or licensed to us. You
          are granted a limited, non-exclusive license to access and use this
          content for personal, non-commercial purposes only. Redistribution or
          use of content for other purposes without prior permission is
          prohibited.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>6. User-Generated Content</h2>
        <p className={styles.text}>
          By submitting any content, such as comments or feedback, you grant us
          a non-exclusive, royalty-free, worldwide license to use, reproduce,
          and distribute this content for purposes of operating and improving
          the Service. You confirm that you have all necessary rights to the
          content you provide.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>7. Limitation of Liability</h2>
        <p className={styles.text}>
          To the fullest extent permitted by law, GrammaGen AI and its
          affiliates, directors, and employees will not be liable for any
          indirect, incidental, or consequential damages arising from your use
          or inability to use the Service. Our liability in all cases is limited
          to the amount you have paid, if any, to access the Service.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>
          8. Modifications to the Service and Terms
        </h2>
        <p className={styles.text}>
          We reserve the right to modify or discontinue the Service, or to
          change these Terms, at any time without notice. Any changes to the
          Terms will be effective immediately upon posting. Continued use of the
          Service after such changes constitutes acceptance of the revised
          Terms.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>9. Termination</h2>
        <p className={styles.text}>
          We reserve the right to suspend or terminate your access to the
          Service at any time, with or without notice, if you breach these Terms
          or for any other reason we deem appropriate.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>10. Governing Law and Disputes</h2>
        <p className={styles.text}>
          These Terms shall be governed by the laws of the European Union. In
          case of disputes, you agree to resolve them in the courts of your
          country of residence within the EU or through online mediation
          services available for consumer rights disputes.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>11. Contact Us</h2>
        <p className={styles.text}>
          If you have any questions about these Terms, please contact us at{" "}
          <a href="mailto:support@grammagen.com">support@grammagen.com</a>.
        </p>
      </section>
    </div>
  );
};
