import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../../../shared/api/baseQueryWithAuth";

export const exerciseApi = createApi({
  reducerPath: "exerciseApi",
  baseQuery: baseQuery,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getExercises: builder.mutation({
      query: ({ level, tenses, count }) => ({
        url: "generate_exercise",
        method: "POST",
        body: { level, tenses, count },
      }),
    }),
    getExercisesForGuest: builder.mutation({
      query: ({ level, tenses, count }) => ({
        url: "generate_exercise_guest",
        method: "POST",
        body: { level, tenses, count },
      }),
    }),
    getSingleExercise: builder.mutation({
      query: ({ request_id }) => ({
        url: `exercise/${request_id}`,
        method: "GET",
      }),
    }),
    checkAnswers: builder.mutation({
      query: ({ request_id, user_answers }) => ({
        url: "check_answers",
        method: "POST",
        body: { request_id, user_answers },
      }),
    }),
    checkGuestAnswers: builder.mutation({
      query: ({ request_id, user_answers }) => ({
        url: "check_answers_guest",
        method: "POST",
        body: { request_id, user_answers },
      }),
    }),
    getUserAnswers: builder.mutation({
      query: ({ request_id }) => ({
        url: `user_answers/${request_id}`,
        method: "GET",
      }),
    }),
    getAnswers: builder.mutation({
      query: ({ request_id }) => ({
        url: `exercise/answers/${request_id}`,
        method: "GET",
      }),
    }),
    getRandomExercise: builder.mutation({
      query: () => ({
        url: `exercise/random`,
        method: "GET",
      }),
    }),
    latestExercises: builder.mutation({
      query: ({ user_id }) => ({
        url: `/exercise/latest/${user_id}`,
        method: "GET",
      }),
    }),
    latestGuestExercises: builder.mutation({
      query: () => ({
        url: `/exercise/latest`,
        method: "GET",
      }),
    }),
    getHistory: builder.mutation({
      query: () => ({
        url: `user_requests_history`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetExercisesMutation,
  useCheckAnswersMutation,
  useCheckGuestAnswersMutation,
  useLatestExercisesMutation,
  useLatestGuestExercisesMutation,
  useGetRandomExerciseMutation,
  useGetHistoryMutation,
  useGetSingleExerciseMutation,
  useGetUserAnswersMutation,
  useGetAnswersMutation,
  useGetExercisesForGuestMutation,
} = exerciseApi;
