import { useEffect, useState } from "react";

export const useFillTheBlankList = (data, initialData) => {
  const sentanses =
    data.sentanses?.length > 0 ? data.sentanses : initialData?.exercises;

  const [doneIds, setDoneIds] = useState([]);

  useEffect(() => {
    setDoneIds([]);
    data?.reset?.();
  }, [sentanses]);

  const gapRegex = /(_+)/g;

  const parseSentence = (sentence) => {
    const replacePlaceholder = sentence.replaceAll(gapRegex, "placeholder");

    return replacePlaceholder.split(" ");
  };

  const clearDone = () => {
    setDoneIds([]);
  };

  const checkAnswers = (answers, correct_answers, setError, clearErrors) => {
    clearDone();
    Object.keys(answers).forEach((key) => {
      if (correct_answers && correct_answers?.[key]) {
        setDoneIds((prev) => [...prev, key]);
      } else {
        setError(key, {
          type: "custom",
          message: "Wrong answer",
        });
      }
    });
  };

  const sentasesWithGaps = sentanses?.map((sentence) => {
    const parts = parseSentence(sentence);
    return parts;
  });

  return {
    sentasesWithGaps,
    checkAnswers,
    doneIds,
    clearDone,
  };
};
