import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  exercise: [],
  request_id: null,
  loading: false,
  user_answers: [],
  level: null,
  tense: null,
};

export const exerciseSlice = createSlice({
  name: "exercise",
  initialState,
  reducers: {
    setExercise: (state, action) => {
      return {
        ...state,
        ...action.payload,
        user_answers: [],
      };
    },
    setInitial: () => {
      return {
        ...initialState,
      };
    },
    setUserAnswers: (state, action) => {
      return {
        ...state,
        user_answers: action.payload,
      };
    },
    setLatestRequest: (state, action) => {
      return {
        ...state,
        latestRequest: action.payload,
      };
    },
  },
});

export const { setLatestRequest, setExercise, setUserAnswers, setInitial } =
  exerciseSlice.actions;

export default exerciseSlice.reducer;
