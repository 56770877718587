import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import { useAnalytics } from "../../hooks/useAnalytic";
import styles from "./styles.module.scss";

export const Layout = ({ children }) => {
  useAnalytics();

  return (
    <div>
      <Header />
      <main className={styles.container}>{children}</main>
      <Footer />
    </div>
  );
};
