import { Button, Popover } from "antd";

import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { useAuth } from "../../module/useAuth";

export const Login = () => {
  const { handleGoogleLogin, handleLoginError } = useAuth();

  return (
    <div>
      <GoogleOAuthProvider clientId="991274265274-o3na1jd2r38v8472neebtfujuns9b7na.apps.googleusercontent.com">
        <Popover
          content={
            <GoogleLogin
              onSuccess={handleGoogleLogin}
              onError={handleLoginError}
              useOneTap
              access_type="offline"
              prompt="consent"
            />
          }
          trigger="click"
        >
          <Button>Login</Button>
        </Popover>
      </GoogleOAuthProvider>
    </div>
  );
};
