import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../../../shared/api/baseQueryWithAuth";

export const authApi = createApi({
  reducerPath: "autheApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    verifyToken: builder.mutation({
      query: (token) => ({
        url: "/verify-token",
        method: "POST",
        body: { token },
      }),
    }),
    refreshToken: builder.mutation({
      query: (token) => ({
        url: "/refresh-token",
        method: "POST",
        body: { token },
      }),
    }),
  }),
});

export const { useVerifyTokenMutation, useRefreshTokenMutation } = authApi;
