import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import generateExerciseReducer from "../features/exercises/module/exerciseSlice";
import { exerciseApi } from "../features/exercises/module/exercisesApi";
import { authApi } from "../features/auth/module/authApi";
import userReducer from "../features/user/module/userSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["user"],
};

const rootReducer = combineReducers({
  exercise: generateExerciseReducer,
  [exerciseApi.reducerPath]: exerciseApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  user: userReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store with the persisted reducer
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(exerciseApi.middleware)
      .concat(authApi.middleware),
});

// Create a persistor
const persistor = persistStore(store);

export { store, persistor };
