import { useDispatch } from "react-redux";
import { useVerifyTokenMutation } from "../module/authApi";
import { setUser, logout } from "../../user/module/userSlice";
import Cookies from "js-cookie";
import { setInitial } from "../../exercises/module/exerciseSlice";

export const useAuth = () => {
  const dispatch = useDispatch();
  const [verifyToken] = useVerifyTokenMutation();

  const handleGoogleLogin = (response) => {
    console.log(response, "response");
    const token = response.credential;

    verifyToken(token)
      .unwrap()
      .then((data) => {
        const expirationTimestamp = data.expaired * 1000; // Convert seconds to milliseconds
        const expirationDate = new Date(expirationTimestamp);
        Cookies.set("authToken", token, {
          expires: expirationDate,
          sameSite: "Lax",
          path: "/",
        });
        dispatch(setUser(data.user));
      })
      .catch((error) => {
        console.error("Verification failed:", error);
      });
  };

  const handleLoginError = () => {
    console.log("Login failed!");
  };

  const doLogout = () => {
    dispatch(logout());
    Cookies.remove("authToken");
    dispatch(setInitial());
  };

  return {
    handleGoogleLogin,
    handleLoginError,
    doLogout,
  };
};
