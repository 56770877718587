import React from "react";

import styles from "./styles.module.scss";
import { Controller } from "react-hook-form";
import { Input } from "antd";

export const FillInTheBlanks = ({
  index,
  sentasesParts,
  doneIds,
  control,
  errors,
}) => {
  let inputIndex = -1;

  const renderInput = (_part, subIndex) => {
    const id = `placeholder-${index}-${subIndex}`;
    const isInValid = errors[id];
    const isDone = doneIds.includes(id);

    return (
      <React.Fragment key={id}>
        <Controller
          name={id}
          control={control}
          render={({ field }) => (
            <Input
              size="small"
              status={isInValid && "error"}
              className={`${styles.input} ${isDone && styles.success}`}
              disabled={isDone}
              autocomplete="off"
              {...field}
            />
          )}
        />
      </React.Fragment>
    );
  };

  const handleRenderInput = (part) => {
    inputIndex++;
    return renderInput(part, inputIndex);
  };

  return (
    <div className={styles.listItem}>
      <p className={styles.text}>
        {index + 1}.{" "}
        {sentasesParts.map((part, subIndex) =>
          part.includes("placeholder") ? (
            handleRenderInput(part, subIndex)
          ) : (
            <React.Fragment key={part + subIndex}> {part} </React.Fragment>
          )
        )}
      </p>
    </div>
  );
};
