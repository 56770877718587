import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { logout } from "../../features/user/module/userSlice";

const prod = "https://api.grammagen.com/v1";
// eslint-disable-next-line no-unused-vars
const dev = "http://127.0.0.1:8000/v1";

const getAuthToken = () => {
  const token = Cookies.get("authToken");
  return token;
};

const baseQuery = fetchBaseQuery({
  baseUrl: prod,
  prepareHeaders: (headers) => {
    const token = getAuthToken();
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    // If a 401 Unauthorized error occurs
    api.dispatch(logout()); // Dispatch a logout action or refresh token logic
  }

  return result;
};

export default baseQueryWithReauth;
