import { Button, notification, Layout } from "antd";
import { Auth } from "../../../features/auth/ui/Auth";
import { useHistory } from "../../../features/exercises/module/useHistory";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../features/user/module/userSlice";
import { History } from "../../../features/exercises/ui/History";
import { useExercise } from "../../../features/exercises/module/useExercise";
import logo from "../../../resources/logodark.png";
import styles from "./styles.module.scss";

const { Header: HeaderAntd } = Layout;

export const Header = () => {
  const user = useSelector(selectCurrentUser);
  const [api, contextHolder] = notification.useNotification();
  const { open, onOpenHistory, onCloseHistory, getHistory, history } =
    useHistory({ notificationApi: api });

  const { onLoadExercise } = useExercise({ notificationApi: api });

  const handleLoadExercise = (request_id) => {
    onLoadExercise(request_id);
  };

  return (
    <>
      <HeaderAntd className={styles.header}>
        <a href="/" className={styles.logo}>
          <img className={styles.img} src={logo} alt="GrammaGen AI Logo" />
          <span className={styles.name}>GrammaGen AI</span>
        </a>
        <h1 className={styles.title}>English Exercise Generator</h1>
        <Auth renderMenu={<Button onClick={onOpenHistory}>History</Button>} />
        {user && (
          <History
            open={open}
            onOpen={onOpenHistory}
            onClose={onCloseHistory}
            getHistory={getHistory}
            data={history}
            onLoadExercise={handleLoadExercise}
          />
        )}
      </HeaderAntd>
      {contextHolder}
    </>
  );
};
