import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={styles.column}>
          <div className={styles.columnContainer}>
            {/* <a href="/" className={styles.logo}>
              <img className={styles.img} src={logo} alt="GrammaGen AI Logo" />
              <span className={styles.name}>GrammaGen AI</span>
            </a> */}
            <span className={styles.copy}>
              A resource where you can generate homework assignments
            </span>
          </div>
        </div>
        <div className={`${styles.column} ${styles.list}`}>
          <ul className={styles.listContainer}>
            <li className={styles.listItem}>
              <Link className={styles.link} to="/terms">
                {" "}
                Terms
              </Link>
            </li>
            <li className={styles.listItem}>
              <Link className={styles.link} to="/privacy-policy">
                {" "}
                Privacy Policy
              </Link>
            </li>
            <li className={styles.listItem}>
              <Link className={styles.link} to="/contacts">
                {" "}
                Contacts
              </Link>
            </li>
          </ul>
        </div>
        <div className={styles.column}>list</div>
      </div>
    </footer>
  );
};
