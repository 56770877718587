import { useState } from "react";
import { useGetHistoryMutation } from "./exercisesApi";

export const useHistory = ({ notificationApi }) => {
  const [open, setOpen] = useState(false);
  const [triggerHistory, { data }] = useGetHistoryMutation();

  const onOpenHistory = async () => {
    try {
      await triggerHistory().unwrap();
      setOpen(true);
    } catch (error) {
      notificationApi.error({
        message: error.data.detail || "Something whent wrong",
        placement: "bottomRight",
      });
    }
  };

  const onCloseHistory = () => {
    setOpen(false);
  };

  return {
    open,
    onOpenHistory,
    onCloseHistory,
    history: data,
  };
};
